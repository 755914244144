/* header */

.long_header {
  width: 100%;
  position: fixed !important;
  top: 0;
  background-color: #0e0e0e;
  z-index: 10;
}

.long_header:after {
  content: "";
  background: white;
  display: block;
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 10;
}

.main_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1920px;
  min-height: 96px;
  background-color: #0e0e0e;
}

/* header texts */

.title {
  font-family: Supermercado One, sans-serif;
  color: var(--lite_green75);
  margin-left: 5%;
  text-decoration: none;
}

.menu {
  width: 520px;
  display: flex;
  justify-content: space-evenly;
}

.text:hover {
  color: #00bafd90;
}

.text {
  font-weight: 600;
  color: var(--blue);
  text-decoration: none;
}

.clock {
  font-family: "Roboto", sans-serif;
  color: var(--green75);
  font-weight: 400;
  opacity: 0.75;
  margin-right: 5%;
}

/* mobile version */

.hamburger {
  display: none;
}

.menu_m {
  display: none;
}

@media only screen and (max-width: 1001px) {
  /* setup mobile */
  .menu {
    display: none;
  }

  .clock {
    display: none;
  }

  .title {
    margin: 0 auto;
  }

  .hamburger {
    display: block;
    position: absolute;
    right: 4%;
    cursor: pointer;
  }

  .menu_m {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 96px;
    width: 100%;
    background-color: black;
    opacity: 90%;
    height: 100%;
  }

  /* mobile texts */

  .margin_left a,
  .margin_left p,
  .margin_left .clock_m {
    margin-left: 40px;
  }

  .menu_m a {
    font-weight: 400;
    margin-top: 32px;
    color: var(--blue);
    text-decoration: none;
  }

  .menu_m a:first-child {
    margin-top: 64px;
  }

  a.active {
    color: var(--white);
    opacity: 90%;
    font-weight: 600;
  }

  .clock_m {
    font-family: Supermercado One, sans-serif;
    color: var(--green75);
    margin-top: 64px;
  }

  .menu_m .warning {
    font-size: 15px;
    width: 70%;
    font-style: italic;
    line-height: 20px;
    margin-top: 32px;
    color: rgb(137, 131, 22);
  }
}

@media screen and (orientation: landscape) {
  .menu_m {
    height: calc(100% - 96px);
    overflow-y: scroll;
  }
}
