.footer {
  clear: both;
  position: relative;
  min-height: 176px;
  width: 100%;
  background-color: #0e0e0e;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid white;
}
.bar1 {
  color: var(--green75);
  opacity: 0.95;
  text-align: center;
  line-height: 32px;
  margin: 0px 2%;
  margin-bottom: 28px;
}
.footer .bar2 {
  display: flex;
  gap: 32px;
}
