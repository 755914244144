.contact_width {
  max-width: 1920px;
  margin: auto;
}

.contact p {
  color: #e1e1e1;
  margin-left: 5%;
  line-height: 32px;
  margin-right: 5%;
  max-width: 500px;
  font-size: 18px;
  margin-top: 200px;
}

.contact a {
  color: var(--blue);
  margin-left: 5%;
  font-size: 18px;
  line-height: 32px;
  text-decoration: underline;
}

.wrapper {
  justify-content: center;
  height: 100vh;
}

.wrapper .message {
  margin-left: 5%;
  margin-right: 5%;
  background-color: #0cfbb7;
  cursor: pointer;
  border-radius: 16px;
  padding: 16px 32px;
  max-width: 302px;
  font-size: 18px;
  display: flex;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-top: 40px;
  gap: 16px;
}

.wrapper .message:hover {
  opacity: 0.7;
}

.wrapper .message p {
  font-weight: 400;
  font-size: 1rem;
}

.wrapper .message img {
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 921px) {
  .contact p {
    font-size: 0.8rem;
  }
  .contact a {
    font-size: 0.8rem;
  }
  .wrapper .message {
    font-size: 0.8rem;
  }
}
