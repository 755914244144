/* animation */

@keyframes lightdark {
  0%,
  35% {
    background-image: url("../../assets/carousel1.jpg");
  }
  65%,
  100% {
    background-image: url("../../assets/carousel2.jpg");
  }
}

/* container */

.carousel {
  width: 100%;
  height: 100%;
  background-color: black;
}

.carousel .body {
  max-width: 1920px;
  margin: auto;
}
.carousel_img {
  height: 100%;
  width: 100%;
  top: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  max-width: 100%;
  position: absolute;
  animation-name: lightdark;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/* carousel texts */

.carousel .heading {
  color: var(--green75);
  opacity: 0.8;
  text-shadow: 0px 100px 80px rgba(0, 0, 0, 0.41),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.244404),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.165596);
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
  font-weight: 500;
  margin-left: 5%;
  margin-right: 5%;
  line-height: 55px;
  margin-top: 160px;
}

.body h3 {
  font-size: 32px;
  margin-top: 24px;
  margin-left: 5%;
  margin-right: 5%;
  font-weight: 200;
  color: #e1e1e1;
  line-height: 55px;
}

.body h6 {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 32px;
  font-size: 18px;
  font-weight: 200;
  line-height: 32px;
  color: #e1e1e1;
}

/* carousel search */

.search_bar {
  display: flex;
  position: relative;
  width: 40%;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 20px;
  background-color: white;
  height: 48px;
}

.search_bar input {
  position: relative;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  /* top: -4px; */
  width: 92%;
  height: auto;
  border-radius: 20px;
  padding-right: 72px;
  padding-left: 48px;
  /* border: 1px solid blue; */
  background-color: transparent;
  outline: none;
  font-size: 0.8rem;
}
input:focus {
  outline: none;
}

.search_bar .button1 {
  background-image: url("../../assets/search.svg");
  background-size: cover;
  width: 24px;
  height: 24px;
  outline: none;
  border: var(--white);
  background-color: var(--white);
  cursor: pointer;
  z-index: 3;
}

.search_bar .button2 {
  height: 28px;
  width: 28px;
  margin-left: -62px;
  outline: none;
  border: black;
  background-color: var(--white);
  cursor: pointer;
  opacity: 0.4;
  z-index: 3;
}

input[type="text"]::-webkit-input-placeholder {
  font-size: 0.8rem;
}

/* mobile version */

@media only screen and (max-width: 921px) {
  .carousel .body {
    margin-top: 18vh;
  }

  /* mobile texts */

  .body .heading {
    line-height: 64px;
  }

  .body h3 {
    font-size: 32px;
    line-height: 40px;
  }

  .body h6 {
    font-size: 0.8rem;
    font-weight: 400;
  }

  /* mobile carousel search */

  .search_bar {
    margin-top: 5vh;
    width: 65%;
  }

  .search_bar input {
    font-size: 13px;
    width: 90%;
  }

  input[type="text"]::-webkit-input-placeholder {
    font-size: 13px;
  }

  .search_bar .button1 {
    margin-left: -16px;
  }
}
