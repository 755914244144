.graph {
  background-color: rgba(0, 0, 0, 0.5);
  margin: 64px auto;
  padding: 48px 0px;
  border-radius: 16px;
  justify-content: center;
  margin-left: 5%;
  margin-right: 5%;
  max-width: 1920px;
}

.graph .heading {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
  color: var(--green75);
  margin-bottom: 64px;
  margin-left: 5%;
  margin-right: 5%;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 75%;
  margin: 10px auto;
}

.item h6 {
  color: var(--green75);
  opacity: 0.8;
  font-size: 20px;
  font-weight: 600;
  width: 64px;
  /* background-color: white; */
  /* width: 120px; */
}

.item:last-child {
  margin-bottom: 40px;
}

.bar_out {
  height: 40px;
  border-radius: 16px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.24);
  border: 4px solid rgba(255, 255, 255, 0.15);
}

.bar_in {
  position: relative;
  height: 100%;
  border-radius: 16px;
  background-color: var(--lite_green75);
  width: 90%;
}

.item p {
  position: absolute;
  right: 10px;
  top: 20%;
  font-weight: 600;
  color: black;
}

@media only screen and (max-width: 1281px) {
  .graph {
    max-width: 100%;
    margin-left: 6%;
    margin-right: 5%;
  }

  .item {
    width: 75%;
    margin: 10px auto;
  }
}

@media only screen and (max-width: 921px) {
  .graph .heading {
    font-size: 24px;
    line-height: 32px;
  }

  .item h6 {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    width: 100%;
  }

  .item p {
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 25%;
    font-weight: 600;
    color: black;
  }

  .bar_out {
    max-width: 100%;
  }

  .item {
    gap: 16px;
    justify-content: center;
    flex-direction: column-reverse;
  }

  .graph {
    margin: 200px auto 0;
    border-radius: 0;
  }
}
