.info2 {
  /* background-color: purple; */
  width: 100%;
  justify-self: center;
}

.info2 .heading {
  line-height: 55px;
  color: var(--blue);
  opacity: 0.8;
  text-shadow: 0px 100px 80px rgba(0, 0, 0, 0.51),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.366616),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.304015),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.255),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.205985),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.143384);
  margin-top: 160px;
  margin-left: 5%;
  margin-right: 5%;
}

.info2 .body {
  margin-left: 5%;
  margin-right: 5%;
  /*  */
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: var(--D80p);
  z-index: 5;
  border-radius: 16px;
  min-height: 0vh;
  max-height: auto;
  max-width: 1920px;
  /* background-color: blue; */
}

.body .pen {
  width: 64px;
  background-color: #e1e1e1;
  padding: 12px;
  /* custom */
  border-radius: 10px;
}

.body .link {
  color: #065cfd;
}

.ega {
  display: none;
}

.info2 p {
  margin-left: 0% !important;
  margin-right: 0% !important;
  font-weight: 600;
  line-height: 32px;
  max-width: 100%;
  color: #e1e1e1;
  text-align: justify;
}

.img img {
  border-radius: 10px !important;
  max-width: 400px;
  outline: 0.5px solid rgba(128, 128, 128, 0.6);
}

.img {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 2000px) {
  .info2 .body {
    max-width: 1920px;
  }
}

@media only screen and (max-width: 1281px) {
  .ega {
    display: none;
  }
}

@media only screen and (max-width: 1281px) {
  .info2 .img {
    display: none;
  }

  .info2 .heading {
    font-size: 32px;
  }

  .info2 p {
    font-size: 0.8rem;
    max-width: 100%;
    font-weight: 400;
  }

  .info2 {
    margin: 200px auto 0;
  }
  .pen {
    margin-left: 5%;
  }
  .ega {
    max-width: 280px;
    max-height: 280px;
    display: flex;
    margin: 0 auto;
    border-radius: 50%;
    outline: 0.5px solid black;
    align-items: center;
  }
}
