html {
  margin: 0;
  font-family: "Barlow", sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #1b1b1b;
  word-spacing: 3px;
}

:root {
  /* color */
  --white: #ffffff;
  --blue: #00bafd;
  --lite_green75: rgba(12, 251, 183, 0.75);
  --lite_green: rgba(12, 251, 183, 1);
  --green75: rgba(0, 227, 98, 1);

  /* carousel */
  --D240p: 240px;
  --M176p: 276px;

  /* margin-top */
  --D192p: 155px;

  /* box-margin */
  --D80p: 48px;
}

.font26 {
  font-size: 18px;
}

.font72 {
  font-size: 6.5vh !important;
  line-height: 7vh !important;
}

.overlay_default {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  height: 100%;
}

.input_default {
  width: 200px;
  height: 24px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  font-family: "Barlow", sans-serif;
  font-size: 0.8rem;
  color: black;
  padding: 0 8px;
}

.borderRadius_default {
  border-radius: 6px;
  border: 0.1px solid rgba(13, 191, 140, 0.5);
}

@media (max-width: 550px) {
  .flexResponsiveTablet_default {
    flex-direction: column;
  }

  .responsiveMargin_default {
    margin-left: 5%;
    margin-right: 5%;
  }
}
